<template>
	<div id="goodsList">
		<div class="el-content mb12">
			<div class="kd-pacel">检索</div>
			<div class="mb12">
				<a-space>
					<span class="f12">搜索：</span>
					<com-shop-search-select @change="(e)=>{ search.shop_id = e }"></com-shop-search-select>
					<a-input-search style="width: 400px;"
                                    v-model:value="search.key"
                                    placeholder="商品名称"
                                    enter-button
                                    @search="getGoodsList(1,info.limit)"
                    />
				</a-space>
			</div>
			<div class="mb12">
				<a-space>
					<span class="f12">状态：</span>
					<a-radio-group v-model:value="search.active"
                                   button-style="solid"
                                   @change="getGoodsList(info.page,info.limit)"
                    >
						<a-radio-button value="0">全部</a-radio-button>
						<a-radio-button value="1">上架中</a-radio-button>
						<a-radio-button value="2">已下架</a-radio-button>
						<a-radio-button value="3">推荐</a-radio-button>
						<a-radio-button value="4">不推荐</a-radio-button>
					</a-radio-group>
				</a-space>
			</div>
			<div>
				<a-space>
					<span class="f12">操作：</span>
					<router-link v-if="store_is_publish_goods == 1"
                        :to="{path:isShop == 0 ?'/goods/goodsEdit':'/many_store/goods/goodsEdit',query:{shop_id:search.shop_id}}"
                    >
						<a-button v-has="{action:'/goods/goodsEdit',plat:isShop}">
                            <i class="ri-add-line"></i>新增商品
                        </a-button>
					</router-link>
					<a-button v-if="search.active !=1"
                              v-has="{action:'wxapp_goods_putaway',plat:isShop}"
                              @click="changeGoodsPutAway(goods_ids,1,1)">
                        <i class="ri-upload-line"></i>上架
                    </a-button>
					<a-button v-if="search.active !=2"
                              v-has="{action:'wxapp_goods_putaway',plat:isShop}"
                              @click="changeGoodsPutAway(goods_ids,0,1)">
                        <i class="ri-download-line"></i>下架
                    </a-button>
					<a-button v-if="search.active !=3"
                              v-has="{action:'wxapp_goods_recommend',plat:isShop}"
                              @click="changeGoodsPutAway(goods_ids,1,2)">
                        <i class="ri-star-smile-line"></i>推荐
                    </a-button>
					<a-button v-if="search.active !=4"
                              v-has="{action:'wxapp_goods_recommend',plat:isShop}"
                              @click="changeGoodsPutAway(goods_ids,0,2)">
                        <i class="ri-star-smile-line"></i>不推荐
                    </a-button>
					<a-button v-has="{action:'wxapp_goods_batch_del',plat:isShop}"
                              @click="deleteGoods(goods_ids)">
                        <i class="ri-delete-bin-line"></i>删除
                    </a-button>
				</a-space>
			</div>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-tabs v-model:activeKey="audit" @change="getGoodsList(info.page,info.limit)" v-if="isShop==1">
				<a-tab-pane tab="审核通过" key="1"></a-tab-pane>
				<a-tab-pane tab="待审核" key="0"></a-tab-pane>
				<a-tab-pane tab="审核失败" key="2"></a-tab-pane>
			</a-tabs>
			<a-table row-key="id" :pagination="false" :data-source="goodsState.info.list" :columns='[
				{title:"ID",dataIndex:"id"},
				{title:"商品",dataIndex:"goods",slots:{customRender:"goods"},width:"600px"},
				{title:"价格",dataIndex:"price"},
				{title:"库存",dataIndex:"count"},
				{title:"状态",dataIndex:"is_put_away",slots:{customRender:"is_put_away"}},
				{title:"推荐状态",dataIndex:"is_recommend",slots:{customRender:"is_recommend"}},
				{title:"排序",dataIndex:"rank"},
				{title:"操作",dataIndex:"action",slots:{customRender:"action"}},
			]' :row-selection="{ selectedRowKeys: goods_ids, onChange:(e)=>{goods_ids = e}  }">
				<template #goods="{record}">
					<div class="kd-goodsList">
						<a-image style="width: 70px;height: 70px;margin-right: 8px;" :src="record.cover"/>
						<div class="kd-goodsList-name">
							<div>{{record.goods_name}}</div>
							<div>
								<a-tag color="orange" v-for="(item,index) in record.cate" :key="index">{{item.name}}</a-tag>
							</div>
						</div>
					</div>
				</template>
				<template #is_put_away="{record}">
					<kd-button :custom="true" title="点击下架"  v-if="record.is_put_away == 1">
						<a-tag color="#00CC66" @click="changeGoodsPutAway(record.id,0,1)">上架</a-tag>
					</kd-button>
					<kd-button :custom="true" title="点击上架"  v-if="record.is_put_away == 0">
						<a-tag color="#999" @click="changeGoodsPutAway(record.id,1,1)">下架</a-tag>
					</kd-button>
				</template>
				<template #is_recommend="{record}">
					<kd-button :custom="true" title="点击设置为不推荐"  v-if="record.is_recommend == 1">
						<a-tag color="#00CC66" @click="changeGoodsPutAway(record.id,0,2)">推荐</a-tag>
					</kd-button>
					<kd-button :custom="true" title="点击设置为推荐"  v-if="record.is_recommend == 0">
						<a-tag color="#999" @click="changeGoodsPutAway(record.id,1,2)">不推荐</a-tag>
					</kd-button>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:isShop == 0 ?'/goods/goodsEdit':'/many_store/goods/goodsEdit',query:{id:record.id,shop_id:search.shop_id}}">
							<kd-button type="primary"
								icon="ri-edit-line"
								title="编辑"
								v-has="{action:'/goods/goodsEdit',plat:isShop}">
							</kd-button>
						</router-link>

						<com-set-live :custom="true"
                                      :extra="record"
                                      :ids="record.live_id"
                                      type="goods"
                                      @success="getGoodsList(info.page,info.limit)"
                        >
							<kd-button type="success"
                                       icon="ri-webcam-line"
                                       title="监控"
                                       v-has="{action:'/goods/goodsEdit',plat:isShop}">
                            </kd-button>
						</com-set-live>

						<kd-button type="danger"
                                   icon="ri-delete-bin-5-line"
                                   title="删除"
                                   v-has="{action:'wxapp_goods_del',plat:isShop}"
                                   @click="deleteGoods(record.id)">
                        </kd-button>
						<kd-button type="success"
                                   icon="ri-qr-code-line"
                                   title="小程序码"
                                   v-has="{action:'wxapp_goods_del',plat:isShop}"
                                   @click="showQrcode(record.id)">
                        </kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination show-size-changer
					:default-current="goodsState.info.page"
					:total="goodsState.info.count"
					@showSizeChange="(p,e)=>{getGoodsList(goodsState.info.page,e)}"
					@change="(e)=>{getGoodsList(e,goodsState.info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="gqState.show" title="商品小程序码" @cancel="gqState.show = false" :footer="null" width="300px">
			<div style="width: 100%;text-align: center;">
				<p style="color: #999;font-size: 12px;" v-if="!gqState.qrcode">请重新编辑并保存商品信息以获取小程序码！</p>
				<a-image style="width: 200px; height: 200px" :src="gqState.qrcode" fallback="/assets/img/default.png"/>
			</div>
		</a-modal>

	</div>
</template>

<script>
import comSetLive from '@/components/miniapp/com-set-live.vue'
import comShopSearchSelect from '@/components/miniapp/com-shop-search-select.vue'
import { onActivated, reactive, toRefs } from 'vue'
import set from '@/api/set.js'

import {useChangeGoodsStatus, useGoodsList, useGoodsQrcode} from '@/models/goods'

export default{
	name:'page-goods-list',
	components:{
		comShopSearchSelect,
		comSetLive
	},
	props:{
		isShop:Number,					//0总后台 1商家端
		is_purchase:Number ,			//1查询批发商品列表
	},
	setup(props){
		const _d = reactive({
			search:{
				sort:"7",//sort 排序
				key:"",
				shop_id:"",
				active:'0',		//根据商品上下架，是否推荐查询商品列表
			},
			audit:"1",		//商品审核状态 0待审核 1审核通过 2审核失败
			goods_ids:[],
			store_is_publish_goods:1,  //是否可以操作商品信息【编辑/新增/删除】
		})

        //如果是多商户需要判断是允许商户发布商品
        if( props.isShop == 1 ){
            set.getFarmSet(['store_is_publish_goods']).then(res=>{
                _d.store_is_publish_goods = res.store_is_publish_goods
            })
        }

        let {goodsState , getGoodsList } = useGoodsList({
            search:_d.search,
            isShop:props.isShop,
            is_purchase:props.is_purchase,
            audit:_d.audit
        })

        let { deleteGoods,changeGoodsPutAway } = useChangeGoodsStatus({ goodsState,getGoodsList })

        let { gqState , showQrcode } = useGoodsQrcode()

		onActivated(()=>{
			getGoodsList(goodsState.info.page,goodsState.info.limit)
		})

		return{
			...toRefs(_d),
            goodsState,
            getGoodsList,
            deleteGoods,
            changeGoodsPutAway,
            gqState,
            showQrcode,
		}
	}
}
</script>
<style lang="scss">
.kd-goodsList{
	width: 100%;
	display: flex;

	&-category{
		color: red;
	}

	&-cover{
		width: 70px;
		height: 70px;
	}
}
</style>
