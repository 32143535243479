import {reactive, toRaw} from "vue";
import goodsModel from "@/api/goods";
import common from "@/api/common";
import set from "@/api/set";

/**
 * 获取商品列表数据
 * @param state= {
 *     search:搜索参数
 *     isShop:是否为多商户
 *     is_purchase:是否为批发商品
 *     state: 多商户 =>商品审核状态 0待审核 1审核通过 2审核失败
 * }
 *
 */
export function useGoodsList(state){
    let goodsState = reactive({
        info:{
            list:[],
            page:1,
            limit:10,
            count:0
        }
    })
    getGoodsList(1,goodsState.info.limit)

    function getGoodsList(page,limit){
        let search = toRaw( state.search )
        if( state.isShop && state.audit ) search.audit = state.audit
        if( state.is_purchase ) search.is_purchase = state.is_purchase
        goodsModel.getGoodsList(page,limit,search,res=>goodsState.info = {limit,...res})
    }
    return { goodsState,getGoodsList }
}

/**
 * 商品状态改变（删除商品，切换商品上下架、推荐状态）
 * @param obj => goodsState商品列表数据 getGoodsList 刷新商品列表方法
 */
export function useChangeGoodsStatus(obj){

    //删除商品数据
    function deleteGoods(id){
        common.deleteDataList(id,8,"确认删除该商品信息吗？").then(()=>{
            let { page,limit } = obj.goodsState.info
            obj.getGoodsList(page,limit)
        })
    }

    //改变商品上下架状态 type 1上下架 2不/推荐
    function changeGoodsPutAway(id,value,type){
        goodsModel.changeGoodsStatus(id,value,type,()=>{
            let { page,limit } = obj.goodsState.info
            obj.getGoodsList(page,limit)
        })
    }

    return{ deleteGoods,changeGoodsPutAway }
}

//显示商品二维码
export function useGoodsQrcode(){
    let gqState = reactive({
        show:false,
        qrcode:""
    })
    function showQrcode(id){
        let path = `/pages/shop/goods_detail?goods_id=${id}`
        set.getQrcode(path,'wx_app').then(res=>{
            gqState.qrcode = res
            gqState.show = true
        })
    }

    return { gqState,showQrcode }
}

//获取商品分类
export function useGoodsCategory(obj){
    let cateState = reactive({
        list:[],
    })

    function getCategoryList(){
        goodsModel.getCategroyList(1,999,obj.search,res=>cateState.list = res)
    }

    return{ cateState ,getCategoryList }
}
